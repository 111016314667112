import { getAllRoutes } from "../config";
import { getBackendApiUrl, getEasyPlvApiToken, checkIfLanguageSwitched, getAuthToken } from "../config";
import jwtDecode from "jwt-decode";
import {toast} from "sonner";
import { IconCalendarDown, IconCalendar, IconCalendarUp, IconSortAscendingLetters, IconSortDescendingLetters } from '@tabler/icons-react';

/**
 * Strips dynamic segments from a route path.
 * 
 * @param {string} path - The route path to normalize.
 * @returns {string} The normalized path without dynamic segments.
 */
const normalizePath = (path) => {
    if (path){
        return path.replace(/\/:\w+/g, '').replace(/\/\d+/g, '');
    }
    
    return '';
};

/**
 * The function `getDataJson` returns an object containing various data arrays along with a JWT token
 * obtained from the `getAuthToken` function.
 * @param [prd] - The `prd` parameter is an array containing product details.
 * @param [tplGroup] - The `tplGroup` parameter in the `getDataJson` function represents template group
 * data. It is an array that contains information about different template groups.
 * @param [tplExt] - The `tplExt` parameter in the `getDataJson` function represents the template
 * extension data. It is an array that contains information related to extending templates or adding
 * additional features to existing templates. This data could include things like additional styling
 * options, customization features, or any other enhancements to the base template design
 * @param [discount] - Discount data for products, such as sale prices or promotional offers.
 * @param [legalMention] - Legal information or disclaimers that need to be displayed on the product
 * page.
 * @param [images] - The `getDataJson` function takes in several arrays as parameters and returns an
 * object with these arrays assigned to specific keys. The `images` parameter is one of the arrays
 * passed to the function and it is assigned to the key "images" in the returned object.
 * @returns An object is being returned with the following properties:
 * - "productDetails": prd
 * - "templateGroupData": tplGroup
 * - "images": images
 * - "templateExtensionData": tplExt
 * - "discountData": discount
 * - "legalMentions": legalMention
 * - "jwt": the result of the function call getAuthToken()
 */
export const getDataJson = (prd = [], tplGroup = [], tplExt = [], discount = [], legalMention = [], images = []) => {
    return {
        "productDetails": prd,
        "templateGroupData": tplGroup,
        "images" : images,
        "templateExtensionData": tplExt,
        "discountData": discount,
        "legalMentions": legalMention,
        "jwt": getAuthToken()
    };
}

/**
 * The function `formatDiscountJsonByIdAndFormValues` formats discount configuration form values with a
 * given discount ID.
 * @param discountId - The `discountId` parameter is the unique identifier of a discount.
 * @param discountConfigFormValues - discountConfigFormValues is an object containing the configuration
 * values for a discount.
 * @returns An object is being returned with the properties `discountId` set to the `discountId`
 * parameter value and `formValues` set to the `discountConfigFormValues` parameter value.
 */
export const formatDiscountJsonByIdAndFormValues = (discountId, discountConfigFormValues) => {
    return {
        discountId: discountId,
        formValues: discountConfigFormValues
    }
}

/**
 * Retrieves the page title, translation key, and screen title from the route path.
 *
 * @param {string|null} [routePath=null] - The route path to find the corresponding title.
 * @returns {[string, string|null, string|null, RouteData|null]} An array containing:
 *   - {string} pageTitle - The title of the page.
 *   - {string|null} translationKey - The translation key for the page title.
 *   - {string|null} screenTitle - The translation key for the screen title.
 *   - {RouteData|null} currentRoute - The current route data object.
 */
export const getPageTitleFromRoute = (routePath = null, isDebug = false) => {
    let pageTitle = "Lafabric";
    let translationKey = null;
    let screenTitle = null;
    const currentRoute = findRouteRecursively(getAllRoutes(), routePath);

    if (isDebug) {
        console.log("Current Route: ", currentRoute);
        console.log("Route Path: ", routePath);
    }

    // If a matching route is found, use its title
    if (currentRoute) {
        pageTitle = currentRoute.title;
        translationKey = currentRoute.translationKey;
        screenTitle = currentRoute?.screenTitletranslationKey;
    }

    return [pageTitle, translationKey, screenTitle, currentRoute];
}

/**
 * @typedef {Object} RouteData
 * @property {string} id - The unique identifier for the route.
 * @property {string} path - The path for the route.
 * @property {JSX.Element} element - The component to be rendered for the route.
 * @property {string} title - The title for the route.
 * @property {boolean} isProtected - Whether the route is protected and requires authentication.
 * @property {string} translationKey - The translation key for the route title.
 * @property {boolean} isSidebarItem - Whether the route should appear in the sidebar.
 * @property {Array<RouteData>} subMenu - The sub-menu items for the route.
 * @property {JSX.Element} icon - The icon to be displayed for the route.
 * @property {string} screenTitletranslationKey - The translation key for the screen title.
 * @property {AdditionalData} additionalData - Additional data for the route.
 */

/**
 * The function `getRouteDataFromRoute` retrieves route data by recursively searching for a specific
 * identifier within all routes.
 * @param {string|undefined|null} [identifier=null] - The `identifier` parameter in the `getRouteDataFromRoute` function is
 * used to specify a unique identifier for a route. This identifier is then used to find and retrieve
 * the route data associated with that specific route. If no identifier is provided, the function will
 * return all route data.
 * @returns {RouteData} The function `getRouteDataFromRoute` is returning the route data obtained by calling the
 * function `findRouteRecursivelyUsingIdentifier` with the parameters `getAllRoutes` and the provided
 * `identifier`.
 */
export const getRouteDataFromRouteId = (identifier = null) => {
	const routeData = findRouteRecursivelyUsingIdentifier(getAllRoutes(), identifier);

	return routeData;
}

/**
 * The function `getPriorityLocale` returns the user's locale if available, otherwise it returns the
 * i18n locale.
 * @param userLocale - The `userLocale` parameter represents the locale preference set by the user. It
 * is a string that indicates the user's preferred language or locale.
 * @param i18Locale - The `i18Locale` parameter in the `getPriorityLocale` function likely represents
 * the locale used for internationalization and localization within the application. It is a default
 * locale that will be used if the `userLocale` is not provided or if a language switch has occurred.
 * @returns The function `getPriorityLocale` returns the priority locale based on the conditions
 * provided in the code. If `userLocale` is not empty and the language has not been switched, it will
 * return `userLocale`. Otherwise, it will return `i18Locale`.
 */
export const getPriorityLocale = (userLocale, i18Locale) => {
    let priorityLocale = 'fr';

    if (userLocale && userLocale != '' && !checkIfLanguageSwitched()){
        priorityLocale = userLocale;
    } else {
        priorityLocale = i18Locale;
    }
    return priorityLocale;
} 

/**
 * The function `getTranslationsByApi` fetches translated data either for all pages or a specific page
 * based on the locale and optional slug.
 * @param locale - The `locale` parameter in the `getTranslationsByApi` function is used to specify the
 * language or region for which you want to retrieve translations. It determines the language in which
 * the content will be fetched from the backend API.
 * @param [slug=null] - The `slug` parameter in the `getTranslationsByApi` function is used to specify
 * a particular page or content to retrieve translations for. If a `slug` is provided, the function
 * will fetch translations for that specific page. If `slug` is not provided (null), the function will
 * fetch
 * @returns The function `getTranslationsByApi` returns the translated data fetched from the backend
 * API based on the specified locale and optional slug. The returned data could be either all pages'
 * translations if no slug is specified or a specific page's translation if a slug is provided. If an
 * error occurs during the fetching process, the function catches the error and logs it to the console.
 */
export const getTranslationsByApi = async (locale, slug = null) => {
    let translatedData = null;
    try {
        if (slug == null){
            //Get all pages if no slug specified

            const allPagesTranslatedData = await fetch(
                getBackendApiUrl(`public/pages/${locale}`),
                {
                    method: "GET",
                    headers: {
                        "X-EasyPLV-Api-Token": getEasyPlvApiToken(),
                    },
                });
            if (allPagesTranslatedData.status == 200)
            {
                translatedData = await allPagesTranslatedData.json();
            }
        } else {
            const pageTranslatedData = await fetch(getBackendApiUrl(`public/pages/${locale}/slug/${slug}`),
            {
                method: "GET",
                headers: {
                    "X-EasyPLV-Api-Token": getEasyPlvApiToken(),
                },
            });
            if (pageTranslatedData.status == 200)
            {
                translatedData = await pageTranslatedData.json();
            }
        }
    } catch (error) {
        console.error(error);
    }

    return translatedData;
}

/**
 * The function __js_priceConvertToFloat converts a string value to a floating-point number by
 * replacing commas with periods and parsing the result.
 * @param value - The `value` parameter in the `__js_priceConvertToFloat` function is the input value
 * that needs to be converted to a floating-point number. If the input value is a string, the function
 * replaces any commas with periods and then parses the value to a floating-point number using
 * `parseFloat`.
 * @returns The function `__js_priceConvertToFloat` returns the parsed floating-point number of the
 * input value after replacing any commas with periods if the input value is a string.
 */
export const __js_priceConvertToFloat = (value) => {
    if (typeof value === 'string') {
        value = value.replace(',', '.');
    }
    return parseFloat(value);
}

/**
 * The function __js_splitCurrency takes an amount as input, converts it to a float with 2 decimal
 * places, and splits it into integer and decimal parts.
 * @param amount - The `__js_splitCurrency` function takes an `amount` parameter, which can be either a
 * number or a string representing a currency amount. The function first checks if the `amount` is a
 * string and replaces any commas with dots if necessary. It then converts the `amount` to a float
 * @returns The function `__js_splitCurrency` returns an object with two properties: `integerPart` and
 * `decimalPart`.
 */
export const __js_splitCurrency = (amount) => {
    // Replace comma with dot if amount is a string
    if (typeof amount === 'string') {
        amount = amount.replace(',', '.');
    }
    
    // Convert to float and format to ensure it has 2 decimal places
    amount = parseFloat(amount).toFixed(2);

    // Check if the conversion results in a valid number
    if (isNaN(amount)) {
        // Provide default values if the amount is not a valid number
        return {
            integerPart: '0',
            decimalPart: '00'
        };
    }
    
    // Split the amount into integer and decimal parts
    const [integerPart, decimalPart] = amount.split('.');
    
    return {
        integerPart: integerPart,
        decimalPart: decimalPart
    };
}

/**
 * The `formatFileSize` function converts a given file size in bytes to a human-readable format with
 * appropriate units (e.g., KB, MB, GB).
 * @param bytes - The `bytes` parameter represents the size of a file in bytes that you want to format
 * into a human-readable file size format.
 * @param [decimals=2] - The `decimals` parameter in the `formatFileSize` function specifies the number
 * of decimal places to include in the formatted file size. By default, it is set to 2 if not provided.
 * You can adjust this parameter to control the precision of the file size output.
 * @returns The `formatFileSize` function returns a formatted string representing the file size in
 * appropriate units (Bytes, KB, MB, GB, etc.) based on the input `bytes` value.
 */
export const formatFileSize = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

/**
 * The `convertDate` function takes a date string in a specific format and converts it to a YYYY-MM-DD
 * format.
 * @returns The function `convertDate` takes a string as input, splits it into parts, and then converts
 * the month abbreviation to its corresponding numerical value. It then returns a string in the format
 * "YYYY-MM-DD" based on the input date string.
 */
export const convertDate = str => {
    str = str.toString();
    let parts = str.split(" ");
    let months = {
      Jan: "01",
      Feb: "02",
      Mar: "03",
      Apr: "04",
      May: "05",
      Jun: "06",
      Jul: "07",
      Aug: "08",
      Sep: "09",
      Oct: "10",
      Nov: "11",
      Dec: "12"
    };
    return parts[3] + "-" + months[parts[1]] + "-" + parts[2];
};

/**
 * The function `getSortingDisplayFromEventKey` returns an object with display text based on the event
 * key and sorting type.
 * @param eventKey - The `eventKey` parameter is a string that represents the sorting order, either
 * 'ASC' for ascending or 'DESC' for descending.
 * @param [type] - The `type` parameter in the `getSortingDisplayFromEventKey` function is used to
 * specify the sorting type. It has a default value of `SORTING_TYPE.NAME` if not provided when calling
 * the function.
 * @param [translationFunction] - Will use the translation function if one is passed
 * @returns An object is being returned with properties `displayText`, `eventKey`, and `sortData`. The
 * `displayText` property will be set based on the `eventKey` provided, defaulting to the label of the
 * default sorting type if no match is found.
 */
export const getSortingDisplayFromEventKey = (eventKey, type = SORTING_TYPE.NAME, translationFunction=null) => {
    let obj = {
        displayText: type.default.label,
        eventKey: eventKey,
        sortData: type
    }

    switch (eventKey) {
        case 'ASC':
            obj.displayText = type.ASC.label;
            break;
        case 'DESC':
            obj.displayText = type.DSC.label;
            break;
        default:
            break;
    }

    if (translationFunction){
        obj.displayText = translationFunction(obj.displayText);
    }

    return obj;
}

//Helper Function to merge localData with NewData for Template Group
/**
 * The function `mergeDataWithoutDuplicates` combines two arrays of data while ensuring no duplicates
 * based on a specified key, with an option to prepend new data.
 * @param localData - `localData` is an array containing the existing data that you have locally. This
 * data may already exist in your system.
 * @param newData - The `newData` parameter in the `mergeDataWithoutDuplicates` function refers to the
 * array of data that you want to merge with the `localData` array. This data will be added to the
 * combined result either before or after the `localData` array based on the `prepend` parameter
 * @param [prepend=false] - The `prepend` parameter in the `mergeDataWithoutDuplicates` function is a
 * boolean flag that determines whether the new data should be added before the local data or vice
 * versa. If `prepend` is set to `true`, the new data will be added before the local data. If `prepend`
 * @returns The function `mergeDataWithoutDuplicates` returns a combined array of data without any
 * duplicates, based on the input parameters `localData`, `newData`, and `prepend`.
 */
export const  mergeDataWithoutDuplicates = (localData, newData, prepend = false) => {
    const combined = new Map();
    if (prepend) {
        // Add new data first
        newData.forEach(item => combined.set(item.id, item));
        // Then add local data, which will overwrite any duplicates
        localData.forEach(item => combined.set(item.id, item));
    } else {
        // Add local data first
        localData.forEach(item => combined.set(item.id, item));
        // Then add new data, which will overwrite any duplicates
        newData.forEach(item => combined.set(item.id, item));
    }
    // Return a combined array of data
    return Array.from(combined.values());
}

// Helper function to filter data based on the search term
/**
 * The `filterData` function filters an array of objects based on a search term matching the object's
 * name property, case-insensitively.
 * @param data - The `data` parameter is an array of objects that you want to filter based on a search
 * term. Each object in the array should have a `name` property that you want to check for a match with
 * the search term.
 * @param term - The `term` parameter in the `filterData` function is the search term that is used to
 * filter the data. It is a string that is used to search for a specific term within the `name`
 * property of each item in the `data` array. The function will return an array of
 * @returns The `filterData` function is returning a new array that contains only the items from the
 * `data` array where the `name` property includes the `term` string (case-insensitive).
 */
export const filterData = (data, term) => {
    return data.filter(item =>
        item.name.toLowerCase().includes(term.toLowerCase())
    );
}

/* The above code is defining a constant object `SORTING_TYPE` in JavaScript. This object contains two
properties: `NAME` and `DATE`, each representing a different sorting type. */
export const SORTING_TYPE = {
    NAME: {
        orderBy: 'name',
        ASC: {
            label: <IconSortAscendingLetters size={'1.5em'}/>,
            key: 'ASC'
        },
        DSC: {
            label: <IconSortDescendingLetters size={'1.5em'}/>,
            key: 'DESC'
        },
        default: {
            label: <IconSortDescendingLetters size={'1.5em'}/>,
            key: 'ASC'
        }
    },
    DATE: {
        orderBy: 'createdAt',
        ASC: {
            label: <IconCalendarUp size={'1.5em'}/>,
            key: 'ASC'
        },
        DSC: {
            label: <IconCalendarDown size={'1.5em'}/>, 
            key: 'DESC'
        },
        default: {
            label: <IconCalendar size={'1.5em'}/>,
            key: 'ASC'
        }
        
    }
}

/**
 * The function `formatLabelforFormatPreview` swaps dimensions based on orientation and aspect ratio,
 * returning the formatted width, height, and aspect ratio with units.
 * @param orientation - The `orientation` parameter in the `formatLabelforFormatPreview` function
 * represents the orientation of the label, which can be either "landscape" or "portrait".
 * @param size - The `size` parameter in the `formatLabelforFormatPreview` function is an object
 * containing the width, height, and unit of a particular size. It has the following structure:
 * @returns The function `formatLabelforFormatPreview` returns an object with the following properties:
 * - `width`: The width of the label with the unit appended.
 * - `height`: The height of the label with the unit appended.
 * - `aspectRatio`: The aspect ratio of the label.
 */
export const formatLabelforFormatPreview = (orientation, size) => {
    let { width, height, unit } = size;
    let aspectRatio = width / height;

    // Check if dimensions should be swapped based on orientation and aspect ratio
    const shouldSwap = (
        (orientation === "landscape" && aspectRatio < 1) || // Landscape orientation but portrait dimensions
        (orientation === "portrait" && aspectRatio > 1) // Portrait orientation but landscape dimensions
    );

    if (shouldSwap) {
        [width, height] = [height, width]; // Swap dimensions
        aspectRatio = 1 / aspectRatio; // Recalculate aspect ratio after swap
    }

    // Build the result object with units appended to width and height
    return {
        width: `${width}${unit}`,
        height: `${height}${unit}`,
        aspectRatio
    };
};

/**
 * The function `generateConfigFormValuesJsonByFormFieldJson` takes a JSON array of form fields and
 * generates a corresponding configuration form values JSON object based on the field types and
 * configurations.
 * @param formFieldsJson - The function `generateConfigFormValuesJsonByFormFieldJson` takes an array of
 * form field objects as input (`formFieldsJson`). Each form field object contains information about
 * the field type and configuration values.
 * @returns The function `generateConfigFormValuesJsonByFormFieldJson` returns an object
 * `configFormValuesJson` that contains key-value pairs based on the input `formFieldsJson`. Each key
 * is generated based on the field type and configuration values, and the corresponding value is an
 * object containing the field name, type, and an initial value specific to that field type.
 */
export const generateConfigFormValuesJsonByFormFieldJson = (formFieldsJson) => {
    if (!formFieldsJson) return {};
    const configFormValuesJson = {};
    for (const formFieldJson of formFieldsJson) {
        const configValues = formFieldJson.configValues;
        const type = formFieldJson.type;
        switch (type) {
            case "boolean":
                const booleanValueKey = `${configValues.frontUniqueKeys.checkbox1}_${formFieldJson.id}`;
                configFormValuesJson[booleanValueKey] = { name : formFieldJson.name, type : 'boolean', value : false };
                break;
            case "collection":
                const collectionValueKey = `${configValues.frontUniqueKeys.select1}_${formFieldJson.id}`;
                configFormValuesJson[collectionValueKey] = { name: formFieldJson.name, value : configValues.values.default, type : 'collection' };
                break;
            case "color_picker":
                const colorPickerValueKey = `${configValues.frontUniqueKeys.select1}_${formFieldJson.id}`;
                configFormValuesJson[colorPickerValueKey] = { name: formFieldJson.name, value : "", type : 'color_picker' };
                break;
            case "ektatek_multiple":
                const ektatekMultipleValueKey = `${configValues.frontUniqueKeys.checkboxgroup1}_${formFieldJson.id}`;
                configFormValuesJson[ektatekMultipleValueKey] = { name: formFieldJson.name, type: 'ektatek_multiple', value: [] };
                break;
            case "ektatek_single":
                const ektatekSingleValueKey = `${configValues.frontUniqueKeys.radiogroup1}_${formFieldJson.id}`;
                configFormValuesJson[ektatekSingleValueKey] = { name: formFieldJson.name, type: 'ektatek_single', value: "" };
                break;
            case "energetic_value":
                const energeticValueKey = `${configValues.frontUniqueKeys.energeticselect1}_${formFieldJson.id}`;
                configFormValuesJson[energeticValueKey] = { name: formFieldJson.name, type: 'energetic_value', value: [] };
                break;
            case "float":
                const floatValueKey = `${configValues.frontUniqueKeys.number1}_${formFieldJson.id}`;
                configFormValuesJson[floatValueKey] = { name: formFieldJson.name, type: 'float', value: "" };
                break;
            case "hidden":
                const hiddenValueKey = `${configValues.frontUniqueKeys.hidden1}_${formFieldJson.id}`;
                configFormValuesJson[hiddenValueKey] = { name: formFieldJson.name, type: 'hidden', value: "" };
                break;
            case "image_multiple":
                const imageMultipleValueKey = `${configValues.frontUniqueKeys.multiplefileinput1}_${formFieldJson.id}`;
                configFormValuesJson[imageMultipleValueKey] = { name: formFieldJson.name, type: 'image_multiple', value: [] };
                break;
            case "image_single":
                const imageSingleValueKey = `${configValues.frontUniqueKeys.fileinput1}_${formFieldJson.id}`;
                configFormValuesJson[imageSingleValueKey] = { name: formFieldJson.name, type: 'image_single', value: "" };
                break;
            case "integer":
                const integerValueKey = `${configValues.frontUniqueKeys.number1}_${formFieldJson.id}`;
                configFormValuesJson[integerValueKey] = { name: formFieldJson.name, type: 'integer', value: "" };
                break;
            case "long_text":
                const longTextValueKey = `${configValues.frontUniqueKeys.textarea1}_${formFieldJson.id}`;
                configFormValuesJson[longTextValueKey] = { name: formFieldJson.name, type: 'long_text', value: "" };
                break;
            case "multiple_collection":
                const multipleCollectionValueKey = `${configValues.frontUniqueKeys.select1}_${formFieldJson.id}`;
                const defaultChoices = configValues.values.default.split();
                if (defaultChoices[0] === '') defaultChoices.pop();
                configFormValuesJson[multipleCollectionValueKey] = { name: formFieldJson.name, type: 'multiple_collection', value: defaultChoices };
                break;
            case "stars":
                const starsValueKey = `${configValues.frontUniqueKeys.radiogroup1}_${formFieldJson.id}`;
                configFormValuesJson[starsValueKey] = { name: formFieldJson.name, type: 'stars', value: 0 };
                break;
            case "string":
                const stringValueKey = `${configValues.frontUniqueKeys.text1}_${formFieldJson.id}`;
                configFormValuesJson[stringValueKey] = { name: formFieldJson.name, type: 'string', value: "" };
                break;
            default:
                break;
        }
    }

    return configFormValuesJson;
}

/**
 * The function `validateFormValuesByFormFieldJson` checks if the form values in a JSON object meet the
 * required criteria based on the field configuration provided.
 * @param formFieldsJson - The `formFieldsJson` parameter seems to be an array containing objects
 * representing form fields. Each object likely contains information about a specific form field, such
 * as its type, configuration values, and unique keys.
 * @param configFormValuesJson - The function `validateFormValuesByFormFieldJson` takes two parameters:
 * `formFieldsJson` and `configFormValuesJson`. The `configFormValuesJson` parameter is an object that
 * contains values for form fields based on their unique keys. These values are used to validate the
 * form fields in the
 * @returns The function `validateFormValuesByFormFieldJson` returns a boolean value. It returns `true`
 * if all the required form field values in `configFormValuesJson` are not empty based on the
 * configuration specified in `formFieldsJson`. If any required field is empty or does not meet the
 * validation criteria based on its type, it returns `false`.
 */
export const validateFormValuesByFormFieldJson = (formFieldsJson, configFormValuesJson) => {
    if (!formFieldsJson) return false;
    if (!configFormValuesJson) return false;

    for (const formFieldJson of formFieldsJson) {
        let formFieldConfigValues = formFieldJson.configValues;
        const required = Boolean(formFieldConfigValues?.values?.required ?? false);
        const type = formFieldJson.type;
        let frontUniqueKeys = formFieldConfigValues.frontUniqueKeys; 
        const frontUniqueKeysArray = Object.entries(frontUniqueKeys);
        
        for (const frontUniqueKey of frontUniqueKeysArray) {
            let key = `${frontUniqueKey[1]}_${formFieldJson.id}`;
            let value = configFormValuesJson[key]['value'];
            // if (!value) return false;
            // TO_DO_NW @priyesh can shorten the conditionals
            if (required) {
                if (type === "collection") {
                    if (value === "") return false;
                }
                else if (type === "multiple_collection") {
                    if (value === "") return false;
                    if (value.length === 0) return false;
                } 
                else if (type === "string") {
                    if (value === "") return false;
                }
                else if (type === "float") {
                    if (value === "") return false;
                }
                else if (type === "integer") {
                    if (value === "") return false;
                }
                else if (type === "energetic_value") {
                    if (value.length === 0) return false;
                }
                else if (type === "color_picker") {
                    if (value === "") return false;
                } 
                else if (type === "long_text") {
                    if (value === "") return false;
                }
                else if (type === "stars") {
                    if (value === 0) return false;
                }
                else if (type === "ektatek_single") {
                    if (value === "") return false;
                }
                else if (type === "ektatek_multiple") {
                    if (value.length === 0) return false;
                } 
                else if (type === "image_single") {
                    if (value === "") return false;
                } else if (type === "image_multiple") {
                    if (value.length === 0) return false;
                }
            }
        }
    }

    return true;
}

/**
 * The function `getBeforeTokenExpiryBuffer` parses an environment variable representing a time buffer
 * before a token expiry and returns it as an integer.
 * @returns The function `getBeforeTokenExpiryBuffer` is returning the value of
 * `process.env.REACT_APP_BEFORE_TOKEN_EXPIRY_BUFFER` after parsing it into an integer using
 * `parseInt()`. If an error occurs during the parsing or if the environment variable is not set, the
 * function will catch the error, log it to the console, and return `null`.
 */
export const getBeforeTokenExpiryBuffer = () => {
    try {
        return parseInt(process.env.REACT_APP_BEFORE_TOKEN_EXPIRY_BUFFER);
    } catch (e) {
        console.log(e);
        return null;
    }
} 

/**
 * The function `getAfterTokenExpiryLimit` parses and returns an integer value from the environment
 * variable `REACT_APP_AFTER_TOKEN_EXPIRY_LIMIT`, handling any errors by logging them and returning
 * null.
 * @returns The function `getAfterTokenExpiryLimit` is returning the value of
 * `process.env.REACT_APP_AFTER_TOKEN_EXPIRY_LIMIT` after parsing it into an integer using
 * `parseInt()`. If an error occurs during the parsing or if the environment variable is not set, the
 * function will catch the error, log it to the console, and return `null`.
 */
export const getAfterTokenExpiryLimit = () => {
    try {
        return parseInt(process.env.REACT_APP_AFTER_TOKEN_EXPIRY_LIMIT);
    } catch (e) {
        console.log(e);
        return null;
    }
}

/**
 * The function `shouldRefreshToken` determines whether a token should be refreshed based on its expiry
 * status and time difference.
 * @param token - The `token` parameter in the `shouldRefreshToken` function is the JWT token that you
 * want to check for refreshing.
 * @param [allowBeforeExpiryRefresh=true] - The `allowBeforeExpiryRefresh` parameter in the
 * `shouldRefreshToken` function is a boolean parameter that determines whether the token should be
 * refreshed before it expires. If set to `true`, the function will check if the token needs to be
 * refreshed based on a specified buffer time before the token expiry
 * @returns The function `shouldRefreshToken` returns a boolean value indicating whether the token
 * should be refreshed.
 */
export const shouldRefreshToken = (token, allowBeforeExpiryRefresh=true) => {
    const jwtTokenData = getDecodedTokenData(token);
    const timestampDifference = jwtTokenData.timestampDifference;
    const minutesDifference = Math.abs(Math.floor(timestampDifference/1000/60));

    if (!timestampDifference) return false;
    if (jwtTokenData.expired) {
        const afterTokenExpiryLimit = getAfterTokenExpiryLimit();
        if (!afterTokenExpiryLimit) return false;
        return minutesDifference < afterTokenExpiryLimit;
    } else {
        if (allowBeforeExpiryRefresh) {
            const beforeTokenExpiryBuffer = getBeforeTokenExpiryBuffer();
            if (!beforeTokenExpiryBuffer) return false;
            return minutesDifference < beforeTokenExpiryBuffer;
        } else {
            return false;
        }
    }
} 

/**
 * The function `getDecodedTokenData` decodes a JWT token, calculates the timestamp difference between
 * the token's expiry and current time, and determines if the token has expired.
 * @param token - The `token` parameter in the `getDecodedTokenData` function is a JSON Web Token (JWT)
 * that contains encoded information such as user details, expiration time, and other data. The
 * function decodes this JWT token using the `jwtDecode` function and then calculates the timestamp
 * difference between
 * @returns The function `getDecodedTokenData` returns the decoded JWT data with additional information
 * about the timestamp difference and whether the token has expired.
 */
export const getDecodedTokenData = (token) => {
    const decodedJwt = jwtDecode(token);
    const expiryTimestamp = decodedJwt.exp * 1000;
    const currentDateTime = new Date();
    const currentTimestamp = currentDateTime.getTime();

    const timestampDifference = currentTimestamp - expiryTimestamp;
    decodedJwt.timestampDifference = timestampDifference;

    if (timestampDifference >=0 ) decodedJwt.expired = true;
    else decodedJwt.expired = false;

    return decodedJwt;
}

/**
 * The `refreshToken` function asynchronously refreshes the authentication token using a current
 * refresh token stored in local storage.
 * @returns The `refreshToken` function returns an object with the following structure:
 * ```javascript
 * {
 *     success: boolean,
 *     newToken: string,
 *     newRefreshToken: string
 * }
 * ```
 */
export const refreshToken = async () => {
    const newTokenData = {
        success: false,
        newToken: "",
        newRefreshToken: "",
    }

    const currentRefreshToken = localStorage.getItem('refresh_token');
    if (!currentRefreshToken) return newTokenData;

    const apiKey = getEasyPlvApiToken();

    const refreshTokenPromise = await fetch(`${getBackendApiUrl()}token/refresh`, {
        method: "POST",
        headers: {
            "X-EasyPLV-Api-Token": apiKey,
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            refresh_token: currentRefreshToken
        })
    });

    if (refreshTokenPromise.status === 200) {
        const responseData = await refreshTokenPromise.json();
        const newToken = responseData?.token;
        const newRefreshToken = responseData?.refresh_token;

        if (newToken && newRefreshToken) {
            newTokenData.success = true;
            newTokenData.newToken = newToken;
            newTokenData.newRefreshToken = newRefreshToken;

            return newTokenData;
        } else {
            return newTokenData;
        }

    } else {
        return newTokenData;
    }
}

/**
 * The function `findRouteRecursively` recursively searches for a specific route in a nested array of
 * routes.
 * @param {Array<RouteData>} routes - Routes is an array of objects representing different routes in a navigation menu.
 * Each route object contains a `path` property that specifies the route's path and a `subMenu`
 * property that is an array of nested route objects representing sub-routes.
 * @param {string} path - The `path` parameter in the `findRouteRecursively` function is the path you are
 * searching for within the nested `routes` array. The function recursively searches through the
 * `routes` array and its submenus to find a route with a matching path.
 * @returns {RouteData|null} The `findRouteRecursively` function returns the route object that matches the given path,
 * or `null` if no matching route is found in the routes array.
 */
export const findRouteRecursively = (routes, path) => {
    const normalizedPath = normalizePath(path);
    
    for (let route of routes) {
        if (normalizePath(route.path) === normalizedPath) {
            return route;
        } else if (route.subMenu && route?.subMenu?.length > 0) {
            const found = findRouteRecursively(route.subMenu, path);
            if (found) {
                return found;
            }
        }
    }
    return null;
};

/**
 * The function `findRouteRecursivelyUsingIdentifier` recursively searches for a route in a nested
 * array of routes based on a given identifier.
 * @param routes - Routes is an array of route objects, where each route object represents a menu item
 * in a navigation menu. Each route object has properties like id (identifier for the route), subMenu
 * (an array of sub-routes), and other properties specific to the route. The function
 * `findRouteRecursivelyUsing
 * @param identifier - The `identifier` parameter is the unique identifier of the route that you want
 * to find within the nested `routes` array. The function `findRouteRecursivelyUsingIdentifier`
 * recursively searches through the `routes` array and its submenus to find the route with the
 * specified identifier. If the route with
 * @returns The function `findRouteRecursivelyUsingIdentifier` returns a route object from the `routes`
 * array that matches the given `identifier`. If no matching route is found, it returns `null`.
 */
const findRouteRecursivelyUsingIdentifier = (routes, identifier) => {
    for (let route of routes) {
        if (route.id === identifier) {
            return route;
        } else if (route.subMenu && route?.subMenu?.length > 0) {
            const found = findRouteRecursivelyUsingIdentifier(route.subMenu, identifier);
            if (found) {
                return found;
            }
        }
    }
    return null;
}

/**
 * Finds the parent route of the given route identifier.
 * 
 * @param {Array<RouteData>} routes - The array of all routes.
 * @param {string} childIdentifier - The identifier of the child route.
 * @returns {RouteData|null} The parent route data object or null if not found.
 */
export const findParentRoute = (routes, childIdentifier) => {
    for (const route of routes) {
        if (route.subMenu) {
            for (const subRoute of route.subMenu) {
                if (subRoute.id === childIdentifier) {
                    return route;
                }
            }
            const parentRoute = findParentRoute(route.subMenu, childIdentifier);
            if (parentRoute) {
                return parentRoute;
            }
        }
    }
    return null;
};

/**
 * The `openInNewTab` function in JavaScript opens a new tab with the specified URL.
 * @param url - The `url` parameter in the `openInNewTab` function is the URL of the webpage that you
 * want to open in a new browser tab.
 */
export const openInNewTab = (url) => {
    window.open(url, '_blank');
}

/**
 * The `base64url_encode` function converts data to a base64url format by replacing characters
 * according to the base64url encoding rules.
 * @param data - The `data` parameter in the `base64url_encode` function represents the input data that
 * you want to encode using the base64url encoding scheme. This function replaces characters in the
 * input data to make it compatible with URLs by replacing `+` with `-`, `/` with `_`, and removing
 * @returns The `base64url_encode` function takes a `data` input and returns the input data with the
 * following replacements:
 * 1. Replaces all occurrences of `+` with `-`
 * 2. Replaces all occurrences of `/` with `_`
 * 3. Removes all occurrences of `=`
 */
export const base64url_encode = (data) => {
    return data
    .replaceAll(/\+/g, '-')
    .replaceAll(/\//g, '_')
    .replaceAll(/=/g, '');
}

/**
 * The function `getInitials` takes a username as input, extracts the first character of each word in
 * the username, and returns the initials as a string.
 * @param username - The `getInitials` function takes a `username` as input and returns the initials of
 * the username.
 * @returns The function `getInitials` takes a `username` as input, extracts the first character of
 * each word in the username, and returns the initials as a string.
 */
export function getInitials(username) {
    // Split the name into words using space as a separator
    let words = username?.trim().split(/\s+/);
    
    // Map each word to its first character and join them to form the initials
    let initials = words?.map(word => word[0]).join('');

    return initials;
}

export function objectToQueryString(obj) {
    let queryString = "";
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (queryString.length > 0) {
          queryString += "&";
        }
        queryString += encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]);
      }
    }
    return queryString;
}

export const getFiltersQueryParam = (key, value) => {
	let queryParam = null;
	switch (key) {
		case 'name':
			queryParam = `filters[name]=${value}`
			break;
        case 'module':
            queryParam = `filters[module]=${value}`
			break;
		default:
			break;
	}

	return queryParam;
}

export const getOptionsQueryParam = (key, value) => {
	let queryParam = '';
	switch (key) {
		case 'page':
			queryParam = `options[page]=${value}`
			break;
		case 'itemsPerPage':
			queryParam = `options[itemsPerPage]=${value}`
			break;
		case 'sort':
			value.forEach((sortObj, index) => {
				if (index > 0) queryParam += '&';
				queryParam += `options[sort][${sortObj.orderBy}]=${sortObj.direction}`;
			});
			break;
		default:
			break;
	}
	return queryParam;
}

/**
 * The `isEmptyObject` function checks if a given object is empty by verifying if it has no keys.
 * @param obj - An object that you want to check if it is empty or not.
 * @returns The function `isEmptyObject` returns a boolean value indicating whether the input object is
 * empty (i.e., has no own enumerable properties). It returns `true` if the object is empty and `false`
 * if it is not empty.
 */
export const isEmptyObject = (obj) => {
	return Object.keys(obj).length === 0;
};


/**
 * This function checks whether the 'NO IMAGE' image is being displayed
 * @param {string} imageUrl - Image Url being passed
 * @param {('operation_products'|'operation_list_item')} [type='operation_products'] - Will be used to check for different type of urls like operation products 
 * and operation list item
 * @returns 
 */
export function isUrlNoImage(imageUrl, type = 'operation_products') {
    try {
        const url = new URL(imageUrl); // Parse the URL

        switch (type) {
            case 'operation_list_item':
                return url.pathname.includes('operation_no_image.png'); // Check only the path        
            default:
                break;
        }

        return url.pathname.includes('product_no_image.png'); // Check only the path
    } catch (e) {
        console.error('Invalid URL');
        return false;
    }
}


/**
 * The function `triggerToast` displays a toast notification with customizable title, description,
 * position, and type.
 * @param [headlessCallback=null] - The `headlessCallback` parameter is a function that can be passed
 * to the `triggerToast` function. If provided, this function will be called with the `toast` function
 * as an argument, allowing for custom toast behavior outside of the `triggerToast` function. If
 * `headlessCallback`
 * @param [options] - The `options` parameter in the `triggerToast` function is an object that can
 * contain the following properties:
 * @returns The `triggerToast` function returns the result of calling the `toast` function with the
 * `headlessCallback` parameter if it is provided. If `headlessCallback` is not provided, the function
 * constructs the `title`, `description`, `position`, and `type` values based on the `options` object
 * and then calls the `toast` function with the appropriate parameters.
 */
export const triggerToast = (
    headlessCallback = null,
    options = {
        title: "",
        description: "",
        position: "top-center",
        type: "info"
    }
) => {
    if (headlessCallback) {
        toast(headlessCallback);
        return;
    }

    const title = options?.title ?? "";
    const description = options?.description ?? "";
    const position = options?.position ?? "top-center";
    const type = options?.type ?? "info";


    toast[`${type}`](title, {...options, position: position, description: description});
}


/**
 * Generate a dynamic URL by replacing placeholders with actual values from params.
 *
 * @param {string} urlTemplate - The URL template with placeholders (e.g., '/free_posters/editor/:mode/:freePosterId')
 * @param {object} params - An object containing the parameters to replace in the template (e.g., { mode: 'edit', freePosterId: 12345 })
 * @returns {string} - The constructed URL with all parameters replaced
 */
export function generateDynamicURL(urlTemplate, params) {
    return urlTemplate.replace(/:(\w+)/g, (_, key) => {
        if (params[key]) {
            return params[key]; // Replace the placeholder with the actual value
        } else {
            throw new Error(`Missing value for parameter: ${key}`);
        }
    });
}
 