import React, { useState, useEffect, useRef } from 'react';
import { NwLoaderSpinner } from '../_utilityComponents/NwIcons/NwIcons';
import './EditorIframePreview.css';

/**
 * @typedef {Object} StyleProps
 * @property {string} [className] - CSS class name for the element.
 * @property {React.CSSProperties} [style] - Inline styles for the element.
 */

/**
 * @typedef {Object} ImageOverlay
 * @property {ReactElement} [header] - Optional header element.
 * @property {ReactElement} [footer] - Optional footer element.
 * @property {boolean} [isHeaderOnPreview] - Flag to set header on the preview itself.
 * @property {boolean} [isFooterOnPreview] - Flag to set Footer on the preview itself
 */

/**
 * @typedef {Object} IframeSlotsProps
 * @property {StyleProps} [root] - Style properties for the root container.
 * @property {StyleProps} [containerWrapper] - Style properties for the container wrapper.
 * @property {StyleProps} [container] - Style properties for the container.
 * @property {Object} [preview] - Style properties for the preview section.
 * @property {StyleProps} [preview.root] - Style properties for the preview root.
 * @property {StyleProps} [preview.spinner] - Style properties for the spinner.
 * @property {StyleProps} [preview.iframeWrapper] - Style properties for the iframe wrapper.
 * @property {StyleProps} [preview.iframe] - Style properties for the iframe.
*/

/**
 * @typedef {Object} EditorIframePreviewProps
 * @property {boolean} [isLoading] - Initial loading state of the iframe.
 * @property {string} [iframeSrc=''] - Source URL for the iframe.
 * @property {IframeSlotsProps} [iframeSlotsProps={}] - Custom styling and class names for various elements in the component.
 * @property {Array<boolean>} [iframeisLoadingDependencies=[]] - Dependencies that determine the iframe's loading state.
 * @property {Array<boolean>} [iframeLoadDependencies=[]] - Dependencies that determine whether the iframe should be rendered.
 * @property {Function} [onIframeRef=() => {}] - Callback function to get the iframe ref.
 * @property {Function} [onIframeLoaded=() => {}] - Callback function to be called when the iframe is loaded.
 * @property {boolean} [isDebug] - Show debug ui/logs for components
 * @property {ImageOverlay} [imgOverlay] - Overlay containing Header and Footer Overlays
 * @property {boolean} [showSpinner] - whether to show the spinner of not
*/

/**
 * EditorIframePreview component for rendering an iframe with dynamic styling and loading states.
 * 
 * @param {EditorIframePreviewProps} props - Props for the component.
 * @returns {JSX.Element} The rendered component.
*/
const EditorIframePreview = ({
    isLoading,
    iframeSrc = '',
    iframeSlotsProps = {},
    iframeLoadDependencies = [],
    onIframeRef = () => {},
    onIframeLoaded = () => {},
    handleIframeMessageData,
    isDebug = false,
    imgOverlay = {},
    showSpinner = true
}) => {

    const [shouldRenderIframe, setShouldRenderIframe] = useState(false);
    const [ iframeIsLoading, setIframeIsLoading ] = useState(isLoading ?? true);
    let hasOverlay = false;
    const iframeRef = useRef(null);

    useEffect(() => {
        // Check if all dependencies are true
        const allDependenciesTrue = iframeLoadDependencies.every(dep => dep);
        if (isDebug) console.log("allDependenciesTrue", allDependenciesTrue);
        setShouldRenderIframe(allDependenciesTrue);
    }, [...iframeLoadDependencies]);

    useEffect(() => {
        if (isDebug) console.log("isLoading: ", isLoading)
        if (isLoading != null || isLoading != undefined){
            setIframeIsLoading(isLoading)
        }
    }, [ isLoading ])

    useEffect(() => {
        setIframeIsLoading(true);
    }, [iframeSrc])

    if (imgOverlay?.header || imgOverlay?.footer) {
        hasOverlay = true
    }


    const OnIframeLoadedHandler = () => {
        if (isDebug) console.log("iframe Ref:", iframeRef);
        setIframeIsLoading(false);
        onIframeRef(iframeRef);
        onIframeLoaded();
    }

    return (
        <div className={`${iframeSlotsProps?.root?.className }`}>
            <div 
                className={`preview-container-wrapper w-100 h-100 ${iframeSlotsProps?.containerWrapper?.className ?? ''}`}
                style={iframeSlotsProps?.containerWrapper?.style}
            >
                <div 
                    className={`preview-container w-100 h-100 d-flex justify-content-center ${ iframeSlotsProps?.container?.className ?? '' }`}
                    style={iframeSlotsProps?.container?.style}
                >
                    <div 
                        className={`preview-nw d-flex flex-column ${ iframeSlotsProps?.preview?.root?.className ?? '' }`}
                        style={iframeSlotsProps?.preview?.root?.style}
                    >
                        {iframeIsLoading && showSpinner ? (
                            <div 
                                className={`preview-nw-spinner position-absolute text-secondary nw-responsive-font-size-xl ${iframeSlotsProps?.preview?.spinner?.className ?? ''}`} 
                                role="status"
                            >
                                <NwLoaderSpinner isSpinner size={'1em'}/>
                            </div>) :
                            (null)
                        }
                        {shouldRenderIframe && 
                            (
                                <div 
                                    className={`preview_iframe_wrapper d-block align-self-center ${ hasOverlay ? 'position-relative' : '' }  ${iframeIsLoading ? 'iframe-is-loading' : 'iframe-is-loaded'} ${iframeSlotsProps?.preview?.iframeWrapper?.className}`} 
                                    style={iframeSlotsProps?.preview?.iframeWrapper?.style} 
                                >
                                    {  imgOverlay?.header &&
                                        React.cloneElement(imgOverlay?.header, {
                                            style: {
                                                transform: imgOverlay?.isHeaderOnPreview ? 'translateY(0%)' : 'translateY(0%)',
                                            }
                                        })
                                    }
                                    <iframe
                                        title={iframeSlotsProps?.preview?.iframe?.title ?? "poster_editor"}
                                        ref={iframeRef} 
                                        id={ iframeSlotsProps?.preview?.iframe?.id ?? 'product_iframe'} 
                                        className={`preview_iframe d-block align-self-center ${iframeIsLoading ? 'd-none' : ''} ${ iframeSlotsProps?.preview?.iframe?.className }`} 
                                        style={iframeSlotsProps?.preview?.iframe?.style} 
                                        src={iframeSrc}
                                        onLoad={OnIframeLoadedHandler}
                                    ></iframe>
                                    { 
                                        imgOverlay?.footer &&
                                        React.cloneElement(imgOverlay?.footer, {
                                            style: {
                                                transform: imgOverlay?.isFooterOnPreview ? 'translateY(0%)' : 'translateY(0%)'
                                            }
                                        })
                                    }
                                </div>                                
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditorIframePreview;